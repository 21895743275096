// eslint-disable-next-line no-unused-vars
import axios, { AxiosRequestConfig } from "axios";
import { Cognito } from "./Cognito";
import { appConfig } from "./AppConfig";
import { appCertification } from "./AppCertification";
import { dateTimeHelper } from "./DateTimeHelper";
import SigV4ClientFactory from "./lib/sigV4Client";
import uritemplate from "url-template";
import { v4 as uuidv4 } from "uuid";
import qs from "qs";
import { commonFunction } from "./CommonFunction";

/**
 * axiosとCognitoアクセスをラップした専用クラスです。
 */
export default class HttpClient {
  /**
   * コンストラクタ
   * @param {String} baseURL
   */
  constructor() {
    axios.defaults.timeout = 60000;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.baseURL = appConfig.APP_CONFIG.BASE_URL;
    this.cognito = new Cognito();
  }

  // バージョンエラーステータスリスト
  versionErrorStatusList = [
    // 最新バージョンのPC画面利用不可
    "LatestVersionPCScreenUnavailable",
    // 最新バージョンのPC画面リリース中
    "LatestVersionPCScreenReleasing",
    // PC画面のバージョン不一致
    "PCScreenVersionMismatch",
    // 最新バージョンのAPI利用不可
    "LatestVersionAPIUnavailable",
    // 最新バージョンのAPIリリース中
    "LatestVersionAPIReleasing",
    // APIのバージョン不一致
    "APIVersionMismatch",
  ];

  // MEMO 外部ライブラリで頻繁に使用され、
  // 不要な解決がされる可能性が高いため、configという変数は避けてください。

  /**
   * HTTPメソッド定数定義
   */
  Method = {
    /**
     * GET
     */
    GET: "GET",
    /**
     * POST
     */
    POST: "POST",
  };

  /**
   * BaseURLを設定します。
   * @param {String} defaultsBaseURL
   */
  setDefaultsBaseURL(defaultsBaseURL) {
    axios.defaults.baseURL = defaultsBaseURL;
  }

  /**
   * 現時点で有効なヘッダーを設定します。
   * @param {AxiosRequestConfig} _config
   */
  setHeader(_config) {
    return new Promise((resolve, reject) => {
      if (_config === undefined) {
        _config = {};
      }

      // axios問題で配列クエリパラメータのシリアライズフォーマット修正
      const paramsSerializer = (params) =>
        qs.stringify(params, { arrayFormat: "indices", allowDots: true });
      _config.paramsSerializer = paramsSerializer;

      // APIアクセスであれば常にトークンを更新して設定します。
      if (
        _config.baseURL == undefined ||
        _config.baseURL == appConfig.APP_CONFIG.BASE_URL ||
        (axios.defaults.baseURL == appConfig.APP_CONFIG.BASE_URL && _config.baseURL == undefined)
      ) {
        this.cognito
          .getIdToken()
          .then((idToken) => {
            _config.headers = { Authorization: idToken };
            resolve(_config);
          })
          .catch((error) => {
            console.error("setHeader error", error);
            reject(_config);
          });
      } else {
        resolve(_config);
      }
    });
  }

  /**
   * axios getのラッパーメソッドです。
   * @param {String} uri
   * @param {AxiosRequestConfig} _config
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get(uri, _config) {
    const newConfig = await this.setHeader(_config);
    return axios.get(uri, newConfig);
  }

  /**
   * axios postのラッパーメソッドです。
   * @param {String} uri
   * @param {Json} data
   * @param {AxiosRequestConfig} _config
   * @returns {Promise<AxiosResponse<any>>}
   */
  async post(uri, data, _config) {
    const newConfig = await this.setHeader(_config);
    return axios.post(uri, data, newConfig);
  }

  /**
   * axios putのラッパーメソッドです。
   * @param {String} uri
   * @param {Json} data
   * @param {AxiosRequestConfig} _config
   * @returns {Promise<AxiosResponse<any>>}
   */
  async put(uri, data, _config) {
    const newConfig = await this.setHeader(_config);
    return axios.put(uri, data, newConfig);
  }

  /**
   * username, passwordでログインします。
   * @param {String} userId
   * @param {String} password
   * @returns {Promise<any>}
   */
  login(userId, password) {
    return this.cognito.login(userId, password);
  }

  /**
   * ログアウトします。
   * @param {String} userId
   */
  logout(userId) {
    // ログインユーザが取得したロックを破棄する
    // ロックの破棄とログアウト処理を並列実行
    try {
      commonFunction.exclusiveReserve(void 0, appConfig.EXCLUSIVE_RESERVE_PROC_DIV.DESTROY, "", "");
    } catch (ex) {
      console.error(ex);
    }
    this.cognito.logout(userId);
  }

  /**
   * トークンをクリアします。
   */
  clearToken() {
    this.cognito.clearToken();
  }

  /**
   * credentialsを取得します。
   * ※ApiGatewayを並列実行したい場合にcredentialsを更新するために呼びます。
   * @returns credentials
   *
  getApiGatewayCredentials() {
    return this.cognito.getIdToken().then((idToken) => {
      return this.cognito.getApiGatewayCredentials(idToken);
    });
  }
*/
  getApiGatewayCredentials() {
    return this.cognito.getIdToken().then((idToken) => {
      return this.cognito.makeCredentials(idToken);
    });
  }
  /**
   * ユーザーの属性を取得します。
   * @param {String} userId
   * @returns {Promise<any>}
   */
  getUserAttribute(userId) {
    return this.cognito.getUserAttribute(userId);
  }

  /**
   * ユーザーのパスワードを変更します
   * @param {String} userId
   * @param {String} oldPassword
   * @param {String} newPassword
   */
  changePassword(userId, oldPassword, newPassword) {
    return this.cognito.changePassword(userId, oldPassword, newPassword);
  }

  /**
   * GetAPIリクエスト用のConfigを作成します。
   * @returns {AxiosRequestConfig}
   */
  createGetApiRequestConfig() {
    const _config = {};
    //const language = window.navigator.language;
    //const lang = language.toLowerCase().split("-");

    _config.params = {
      // reqComLanguageCd: lang[0],
      reqComCompanySid: sessionStorage.getItem("comp_sid"),
      //reqComCompanySid: "2100000100",
      reqComOfficeSid: sessionStorage.getItem("sales_office_sid"),
      reqComDeviceImei: "WEB", //ブランク
      reqComAppVersion: appCertification.APPVERSION, //ブランク
      reqComExecUser: sessionStorage.getItem("usercode"),
      reqComExecTimestamp: dateTimeHelper.convertUTC(),
      reqComRequestId: uuidv4(),
      reqComPaginationFlg: "0",
      reqComPageIndex: "1",
      reqComPageLimit: "100",
      reqComLanguageCd: sessionStorage.getItem("lang"),
      reqComReferenceDate: dateTimeHelper.convertUTC(),
      reqComSortItem: "",
      reqComTimeZone: dateTimeHelper.getTimeZone(),
    };

    return _config;
  }
  /**
   * PostAPIリクエスト用のConfigを作成します。
   * @returns {AxiosRequestConfig}
   */
  createPostApiRequestConfig() {
    const _config = {};
    //const language = window.navigator.language;
    //const lang = language.toLowerCase().split("-");

    _config.params = {
      // reqComLanguageCd: lang[0],
      MessageGroupId: "",
    };
    return _config;
  }

  /**
   * PostAPIリクエスト用のConfigを作成します。
   * @returns
   */
  createRequestBodyConfig() {
    const body = {};
    let kvp = {};
    kvp.reqComComponentId = "";
    kvp.reqComCompanySid = sessionStorage.getItem("comp_sid");
    kvp.reqComOfficeSid = sessionStorage.getItem("sales_office_sid");
    kvp.reqComDeviceImei = "WEB";
    kvp.reqComAppVersion = appCertification.APPVERSION;
    kvp.reqComExecUser = sessionStorage.getItem("usercode");
    kvp.reqComExecTimestamp = dateTimeHelper.convertUTC();
    kvp.reqComRequestId = uuidv4();
    kvp.reqComLanguageCd = sessionStorage.getItem("lang");
    kvp.reqComTimeZone = dateTimeHelper.getTimeZone();

    body.reqCom = kvp;
    body.reqIdv = {};
    return body;
  }

  /**
   * API GatewayのGetリクエストです。
   * @param {String} uri
   * @param {any} data
   * @param {AxiosRequestConfig} _config
   * @returns {Promise<R>}
   */
  async secureGet(uri, _config) {
    const newConfig = await this.setHeader(_config);
    return axios
      .get(uri, newConfig)
      .then((response) => {
        // ログ出力
        // console.info("response", response);
        // バージョンエラーステータスリスト
        const versionErrorStatusList = [
          // 最新バージョンのPC画面利用不可
          "LatestVersionPCScreenUnavailable",
          // 最新バージョンのPC画面リリース中
          "LatestVersionPCScreenReleasing",
          // PC画面のバージョン不一致
          "PCScreenVersionMismatch",
          // 最新バージョンのAPI利用不可
          "LatestVersionAPIUnavailable",
          // 最新バージョンのAPIリリース中
          "LatestVersionAPIReleasing",
          // APIのバージョン不一致
          "APIVersionMismatch",
        ];
        // レスポンスステータス
        const resComStatus = response.data.resCom.resComStatus;
        // バージョンエラーに該当する場合
        if (versionErrorStatusList.includes(resComStatus)) {
          // ログアウトする
          this.logout();
          // ログ出力
          // console.info("logout");
          // エラーメッセージ表示
          alert(response.data.resCom.resComMessage);
          // PC画面のバージョン不一致の場合
          if (resComStatus == "PCScreenVersionMismatch") {
            // キャッシュをクリアする
            window.location.reload(true);
          }
          // ログイン画面へ遷移
          window.location.href = "/";
        }
        // responseを返す
        return response;
      })
      .catch((ex) => {
        console.error(ex);
        throw ex;
      });
    //return this.apiGatewayRequest(this.Method.GET, url, {}, _config);
  }

  /**
   * API GatewayのPostリクエストです。
   * @param {String} uri
   * @param {any} data
   * @param {AxiosRequestConfig} _config
   * @returns {Promise<R>}
   */
  async securePost(uri, data, _config) {
    const newConfig = await this.setHeader(_config);
    return axios
      .post(uri, data, newConfig)
      .then((response) => {
        // ログ出力
        // console.info("response", response);
        // バージョンエラーステータスリスト
        const versionErrorStatusList = [
          // 最新バージョンのPC画面利用不可
          "LatestVersionPCScreenUnavailable",
          // 最新バージョンのPC画面リリース中
          "LatestVersionPCScreenReleasing",
          // PC画面のバージョン不一致
          "PCScreenVersionMismatch",
          // 最新バージョンのAPI利用不可
          "LatestVersionAPIUnavailable",
          // 最新バージョンのAPIリリース中
          "LatestVersionAPIReleasing",
          // APIのバージョン不一致
          "APIVersionMismatch",
        ];
        // レスポンスステータス
        const resComStatus = response.data.resCom.resComStatus;
        // バージョンエラーに該当する場合
        if (versionErrorStatusList.includes(resComStatus)) {
          // ログアウトする
          this.logout();
          // ログ出力
          // console.info("logout");
          // エラーメッセージ表示
          alert(response.data.resCom.resComMessage);
          // PC画面のバージョン不一致の場合
          if (resComStatus == "PCScreenVersionMismatch") {
            // キャッシュをクリアする
            window.location.reload(true);
          }
          // ログイン画面へ遷移
          window.location.href = "/";
        }
        // responseを返す
        return response;
      })
      .catch((ex) => {
        console.error(ex);
        throw ex;
      });
    //return this.apiGatewayRequest(this.Method.POST, uri, data, _config);
  }

  /**
   * バッチ起動APIのPostリクエストです。
   * @param {String} uri
   * @param {any} data
   * @param {AxiosRequestConfig} _config
   * @returns {Promise<R>}
   */
  async secureBatchPost(uri, data, _config) {
    const newConfig = await this.setHeader(_config);
    return axios
      .post(uri, data, newConfig)
      .then((response) => {
        // レスポンスを返す
        return response;
      })
      .catch((ex) => {
        console.error(ex);
        throw ex;
      });
  }

  /**
   * ApiGatewayへの署名付きAPIアクセスを行う実体です。
   * @param {String} method
   * @param {String} url
   * @param {any} data
   * @param {AxiosRequestConfig} _config
   * @returns {Promise<R>}
   */
  apiGatewayRequest(method, url, data, _config) {
    if (_config.baseURL === undefined || _config.baseURL == "") {
      _config.baseURL = appConfig.APP_CONFIG.BASE_URL;
    }
    // トークンを再取得します。
    return this.cognito
      .getIdToken()
      .then(() => {
        // baseUrlの末尾スラッシュを補正します。
        let baseUrl = this.removeUrlEndSlash(_config.baseURL);

        // baseUrlの先頭スラッシュがあれば削除します。
        let _url;
        if (url[url.length - 1] == "/") {
          _url = url;
        } else {
          _url = "/" + url;
        }
        // 署名付きリクエスト発行クライアントを初期化します。
        const clientConfig = {
          endpoint: baseUrl,
          region: sessionStorage.getItem("COGNITO.REGION"),
          serviceName: "execute-api",
          accessKey: sessionStorage.getItem("credentials.accessKeyId"),
          secretKey: sessionStorage.getItem("credentials.secretAccessKey"),
          sessionToken: sessionStorage.getItem("credentials.sessionToken"),
          defaultContentType: "application/json",
          defaultAcceptType: "application/json",
        };
        const sigV4ClientFactory = SigV4ClientFactory.newClient(clientConfig);
        // 署名付きリクエストに変換してリクエストします。
        const request = {
          verb: method.toUpperCase(),
          path: uritemplate.parse(_url).expand(_config.params),
          headers: _config.headers || {},
          queryParams: _config.params,
          body: data,
        };
        const sigV4Request = sigV4ClientFactory.makeRequest(request);
        return axios(sigV4Request);
      })
      .catch((err) => {
        console.error("apiGatewayRequest getIdToken error", err);
        return err;
      });
  }
  removeUrlEndSlash(url) {
    return url.replace(/\/$/, "");
  }

  /** 非同期処理 */
  async asynchronousApiAsync(method, apiUrl, _config, body) {
    try {
      const startExecutionUrl = sessionStorage.getItem("AWS.STARTEXECUTIONURL");
      const stateMachineArn = sessionStorage.getItem("AWS.STATEMACHINEARN");
      let apiUrlParams = sessionStorage.getItem("AWS.APIURL") + apiUrl;
      let newConfig = await this.setHeader(_config);
      let newConfigPost = "";
      let inputValue = {};
      if (method == "GET") {
        apiUrlParams = apiUrlParams + "?" + new URLSearchParams(newConfig.params).toString();
        newConfigPost = await this.setHeader(appConfig.APP_CONFIG);
        inputValue = {
          apiUrl: apiUrlParams,
          body: "",
          method: method,
        };
      } else if (method == "POST") {
        newConfigPost = newConfig;
        inputValue = {
          apiUrl: apiUrlParams,
          body: body,
          method: method,
        };
      }
      const requestValue = {
        input: JSON.stringify(inputValue),
        name: (
          dateTimeHelper.convertUTC().replace(/[^0-9]/g, "") +
          "_" +
          apiUrl.replace(/[^0-9a-zA-Z]/g, "")
        ).substring(0, 80),
        stateMachineArn: stateMachineArn,
      };

      const executionArn = await this.startExecutionAsync(
        startExecutionUrl,
        requestValue,
        newConfigPost
      );

      const describeExecutionUrl = sessionStorage.getItem("AWS.STARTEXECUTIONURL") + "/status";
      const delay = 2000;
      let response = null;

      do {
        await new Promise((resolve) => setTimeout(resolve, delay));
        response = await this.describeExecutionAsync(
          executionArn,
          describeExecutionUrl,
          newConfigPost
        );
      } while (response && response.status === "RUNNING");
      return response;
    } catch (e) {
      // handleException(e);
    } finally {
      // handleCleanup
    }
  }

  async startExecutionAsync(startExecutionUrl, requestValue, newConfig) {
    const jsonData = JSON.stringify(requestValue);
    return axios
      .post(startExecutionUrl, jsonData, newConfig)
      .then((response) => {
        const jsonData = JSON.parse(JSON.stringify(response.data));
        // レスポンスを返す
        return jsonData.executionArn;
      })
      .catch((ex) => {
        console.error(ex);
        throw ex;
      });
  }

  describeExecutionAsync(executionArn, describeExecutionUrl, newConfig) {
    const reqbody = {
      executionArn: executionArn,
    };
    return axios
      .post(describeExecutionUrl, reqbody, newConfig)
      .then((response) => {
        return JSON.parse(JSON.stringify(response.data));
      })
      .catch((ex) => {
        console.error(ex);
        throw ex;
      });
  }
}

export const httpClient = new HttpClient();
