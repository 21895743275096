// コメント位置はPrettier対策
class AppCertification {
  //FIXME 本番設定に書き換えてください。
  /**
   * アプリケーションの定数値です。
   */
  APPVERSION = "24.11.02";
  APP_CONFIG = {
    //FIXME MockModeの有効無効
    // HttpClientのgetを対象として、dummy.jsonを読み込むフラグです。
    MOCK_MODE: false,

    /**
     * API接続用設定（VDI外アクセス）
     */
    // 本番環境
    BASE_URL: "https://next-wms.nikkon.jp/",
  };

  /**
   * Cognitoの設定です。
   */
  //FIXME 本番設定に書き換えてください。
  //営業所用
  COGNITO = {
    USER_POOL_ID: "ap-northeast-1_w7dQtzyAU",
    APP_CLIENT_ID: "307kjotv7g5baqacanvf530maf",
  };

  //荷主用
  SHIPPER_COGNITO = {
    USER_POOL_ID: "ap-northeast-1_FU0vRyvjZ",
    APP_CLIENT_ID: "5bdllf5unmk0uj1l66nlg9qeei",
  };

  /** datadog */
  DATADOG = {
    DD_APPLICATION_ID: "9a53f9ab-4411-4672-bd8b-230abe646e8a",
    DD_CLIENT_TOKEN: "pub074d36615f0b243a6502f86c6e91e805",
    DD_SERVICE: "wms-pr-pc",
    DD_ENV: "product",
    DD_SESSION: 75,
  };
  /** datadog */
}

export const appCertification = new AppCertification();
